import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popover, { PopoverProps } from 'antd/lib/popover';
import ProfilePopoverContent from './ProfilePopoverContent';

type Props = {
  user_id: number;
  overflow?: boolean;
} & PopoverProps;

const OFFSET_TOP = 400;

const ProfilePopover = (props: Props) => {
  const { user_id, children, overflow, ...popoverProps } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [align, setAlign] = useState({
    points: ['bc', 'tc'], // align popover to the top of the element
    offset: [0, -10] // offset popover by -10px in the y direction
  });

  const handleScroll = useCallback(() => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    if (rect.top > OFFSET_TOP) {
      setAlign({
        points: ['bc', 'tc'], // align popover to the top of the element
        offset: [0, -10] // offset popover by -10px in the y direction
      });
    } else {
      setAlign({
        points: ['tc', 'bc'], // align popover to the bottom of the element
        offset: [0, 10] // offset popover by 10px in the y direction
      });
    }
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Popover
      content={<ProfilePopoverContent id={user_id} />}
      trigger="hover"
      align={align}
      overlayInnerStyle={{
        borderRadius: '10px',
        padding: '20px',
        width: 250
      }}
      autoAdjustOverflow={false}
      overlayClassName="profile-popup"
      getPopupContainer={overflow ? () => document.body : (trigger) => trigger.parentElement}
      destroyTooltipOnHide
      {...popoverProps}>
      <span ref={ref} style={{ width: 'fit-content', height: 'fit-content' }}>
        {children}
      </span>
    </Popover>
  );
};

export default ProfilePopover;
