import React from 'react';
import { Skeleton } from 'antd';
import { Flex } from 'src/styled/Layout';

const ProfilePopoverContentSkeleton = () => {
  return (
    <Flex col center>
      <Skeleton.Avatar active shape="circle" size={150} />
      <Skeleton.Button active style={{ width: 170, marginTop: 16 }} shape="round" />
      <Skeleton.Button active style={{ width: 140, marginTop: 8 }} shape="round" size="small" />
      <Skeleton.Button active style={{ width: 170, marginTop: 12, borderRadius: 5 }} />
    </Flex>
  );
};

export default ProfilePopoverContentSkeleton;
