import React, { useCallback, useEffect, useState } from 'react';
import { Image } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'src/activities/images/SVG';
import { ProfileApi } from 'src/api/ProfileApi';
import ProfilePopoverContentSkeleton from 'src/components/Skeleton/ProfilePopoverContentSkeleton';
import { IAppState } from 'src/interface/IAppState';
import { IdataOtherProfile } from 'src/interface/IOtherProfileState';
import { IdataProfile, IprofileState } from 'src/interface/IProfileState';
import { followProfile } from 'src/profile/OtherProfile/OtherProfileAction';
import { Button as B, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import { getLinkProfile } from 'src/utils/getLink';

type Props = {
  id: number;
};

const ProfilePopoverContent = (props: Props) => {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<IdataOtherProfile | IdataProfile>();
  const [isFollow, setIsFollow] = useState(false);
  const profileData: IprofileState = useSelector((state: IAppState) => state.headerState);
  const dispatch = useDispatch();
  const router = useRouter();

  const handleFollow = () => {
    dispatch(followProfile(id, !isFollow));
    setIsFollow(!isFollow);
  };

  const handleRedirect = () => {
    router.push(getLinkProfile(id, profileData.data.id));
  };

  const getUserData = useCallback(async () => {
    const data: IdataOtherProfile = await ProfileApi.getOtherProfile(id.toString());
    setUser(data);
    if (data) setIsFollow(data.is_followed);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (profileData.data.id !== id) {
      getUserData();
    } else {
      setUser(profileData.data);
      setIsLoading(false);
    }
  }, [getUserData, id, profileData.data]);

  return isLoading ? (
    <ProfilePopoverContentSkeleton />
  ) : user ? (
    <Flex col center>
      <Image
        src={user.avatar_url}
        width={150}
        wrapperClassName="profile-image-ava"
        preview={false}
        style={{ marginBottom: 12 }}
        onClick={handleRedirect}
      />
      <T.Normal
        style={{ marginBottom: 8, textAlign: 'center' }}
        fz={18}
        fw={600}
        click
        hover
        onClick={handleRedirect}>
        {user.full_name}
      </T.Normal>
      <T.Normal style={{ marginBottom: 12 }} fz={12} fw={400}>
        {user.email}
      </T.Normal>
      {profileData.data.id === user.id ? (
        <B.DarkBtn style={{ padding: 10, borderRadius: '5px' }} h={36} onClick={handleRedirect}>
          <T.Normal fm="Poppins" fz={16} fw={500} color="white" click>
            View Profile
          </T.Normal>
        </B.DarkBtn>
      ) : isFollow ? (
        <B.DarkBtn style={{ padding: 10, borderRadius: '5px' }} h={36} onClick={handleFollow}>
          <Flex itemCenter gap="10px">
            <SVG
              icon="following"
              viewBox="0 0 512 512"
              width="16"
              height="16"
              fill="white"
              strokeWidth={0}
            />
            <T.Normal fm="Poppins" fz={16} fw={500} color="white" click>
              Following
            </T.Normal>
          </Flex>
        </B.DarkBtn>
      ) : (
        <B.DefaultBtn
          style={{ padding: 10, borderRadius: '5px', border: '1px solid #1F2D3A' }}
          bg_hover="rgba(31, 45, 58, 0.10)"
          h={36}
          onClick={handleFollow}>
          <Flex itemCenter gap="10px">
            <SVG
              icon="follow"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              fill="#1F2D3A"
              strokeWidth={0}
            />
            <T.Normal fm="Poppins" fz={16} fw={500} color="#1F2D3A" click>
              Follow
            </T.Normal>
          </Flex>
        </B.DefaultBtn>
      )}
    </Flex>
  ) : null;
};

export default ProfilePopoverContent;
